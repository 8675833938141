import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import ExternalLink from "../assets/external-link.svg";

const FooterInstagramFeedStyles = styled.div`
  grid-column: 5 / span 4;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    margin-bottom: 2em;
  }

  a {
    display: inline-block;
    margin-bottom: 1em;
    @media ${(props) => props.theme.breakpoints.m} {
      margin-bottom: 0;
    }
  }

  .follow {
    margin-bottom: 2em;
    color: var(--color-white);
    img {
      width: 1.2em;
      margin-left: 0.6em;
      margin-bottom: -0.2em;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    .instagram-wrapper {
      width: 20%;
      margin-right: 6.4%;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
  }
`;

const FooterInstagramFeed = ({ contentInstagram }) => {
  return (
    <FooterInstagramFeedStyles>
      <a
        href="https://www.instagram.com/jj_studenthousing/"
        target="_blank"
        rel="noopener noreferrer"
        className="follow"
      >
        Follow Us on Instagram
        <img src={ExternalLink} alt="External Link Icon" />
      </a>
      <div className="images">
        {contentInstagram.map((content, i) => (
          <div key={i} className="instagram-wrapper">
            <a
              href={content.permalink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={content.localImage.childImageSharp.gatsbyImageData}
                alt="Students"
              />
            </a>
          </div>
        ))}
      </div>
    </FooterInstagramFeedStyles>
  );
};

export default FooterInstagramFeed;
