import React from "react";
import styled from "styled-components";
import Logo from "../assets/Logo.svg";
import MobileHamburger from "./MobileHamburger";
import { motion, useCycle } from "framer-motion";
import { Link } from "gatsby";
import links from "./data/menuLinks";

const HeaderStyles = styled.header`
  height: 14vh;
  grid-column: 2 / span 12;
  grid-row: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    height: 7em;
  }

  .logo {
    width: 240px;
    @media ${(props) => props.theme.breakpoints.m} {
      width: 190px;
    }
  }

  nav {
    @media ${(props) => props.theme.breakpoints.m} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .menu-items {
      display: flex;
      @media ${(props) => props.theme.breakpoints.m} {
        flex-direction: column;
        align-items: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }
      li {
        list-style: none;
        margin-right: 2em;
        @media ${(props) => props.theme.breakpoints.m} {
          margin-right: 0;
          margin-bottom: 0.7em;
          display: ${(props) => (props.isOpen ? "block" : "none")};
        }
        &:last-child {
          margin-right: 0;
        }
        a:not(.btn) {
          display: flex;
          align-items: center;
          color: var(--color-black);
          text-decoration: none;
          padding-top: 0.6em;
          position: relative;

          &:after {
            background: none repeat scroll 0 0 transparent;
            bottom: -10px;
            content: "";
            display: block;
            height: 4px;
            border-radius: 4px;
            left: 50%;
            position: absolute;
            background: var(--color-accent);
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
            @media ${(props) => props.theme.breakpoints.m} {
              display: none;
            }
          }
          &:hover:after {
            width: 50%;
            left: 25%;
          }
          &.active:after {
            width: 50%;
            left: 25%;
          }
        }
        .btn {
          margin: 0;
        }
      }
    }
  }
  .mobile-background {
    display: none;
    @media ${(props) => props.theme.breakpoints.m} {
      position: fixed;
      display: block;
      width: 100%;
      min-height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-white);
      z-index: 3;
    }
  }
`;

const Header = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  const topMenuVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const menuBgVariants = {
    open: {
      opacity: 1,
      scaleY: 1,
    },
    closed: {
      opacity: 0,
      scaleY: 0,
    },
  };

  // const singleMenuItemVariants = {
  //   open: {
  //     y: 0,
  //     opacity: 1,
  //     transition: {
  //       y: { stiffness: 1000, velocity: -100 },
  //     },
  //   },
  //   closed: {
  //     y: 50,
  //     opacity: 0,
  //     transition: {
  //       y: { stiffness: 1000 },
  //     },
  //   },
  // };

  return (
    <HeaderStyles isOpen={isOpen}>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="J&amp;J Investments" />
        </Link>
      </div>
      <motion.nav initial={false} animate={isOpen ? "open" : "closed"}>
        <motion.ul variants={topMenuVariants} className="menu-items">
          {links.map((link) => (
            <motion.li key={link.name}>
              {link.internal ? (
                <Link
                  to={link.url}
                  activeClassName="active"
                  className={link.linkClass && link.linkClass}
                >
                  {link.name}
                </Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={link.linkClass && link.linkClass}
                  href={link.url}
                >
                  {link.name}
                </a>
              )}
            </motion.li>
          ))}
        </motion.ul>

        <MobileHamburger toggle={() => toggleOpen()} />
      </motion.nav>
      <motion.div
        initial={{ scaleY: 0 }}
        animate={isOpen ? "open" : "closed"}
        className="mobile-background"
        variants={menuBgVariants}
        style={{ originY: 0 }}
      />
    </HeaderStyles>
  );
};

export default Header;
