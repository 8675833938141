import React from "react";
import styled from "styled-components";
import MaxWidthSection from "../components/Layout/MaxWidthSection";
import Row from "../components/Layout/Row";
import FooterNav from "./FooterNav";
import FooterInstagramFeed from "./FooterInstagramFeed";
import FooterNews from "./FooterNews";
import { useStaticQuery, graphql } from "gatsby";

const FooterStyles = styled.footer`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 2em 0;
  margin-top: 2em;

  background-color: var(--color-dark-gray);
  color: var(--color-white);

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .row-footer {
    width: 100%;
    a {
      text-decoration: none;
      color: var(--color-white);
      margin-bottom: 0.4em;
      transition: all 0.2s;
      &.active {
        font-weight: 900;
      }
      &:hover {
        color: var(--color-accent-light);
      }
    }
  }

  .copyright {
    margin-top: 2em;
    text-align: center;
    font-size: 0.9em;
    color: var(--color-light-gray);
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query InstagramandNewsQuery {
      allInstagramContent(limit: 8) {
        nodes {
          localImage {
            childImageSharp {
              gatsbyImageData(
                width: 100
                height: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          permalink
        }
      }
      allSanityNews(limit: 2, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          title
          publishedAt
          news
        }
      }
    }
  `);

  const contentInstagram = data.allInstagramContent.nodes;
  const news = data.allSanityNews.nodes;

  return (
    <FooterStyles>
      <MaxWidthSection>
        <FooterNav />
        <FooterInstagramFeed contentInstagram={contentInstagram} />
        <FooterNews news={news} />
      </MaxWidthSection>
      <MaxWidthSection>
        <Row>
          <div className="copyright">
            © {new Date().getFullYear()} J&#38;J Investment Properties LLC
          </div>
        </Row>
      </MaxWidthSection>
    </FooterStyles>
  );
};

export default Footer;
