import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  :root {
    --font-family-sans: -apple-system, BlinkMacSystemFont, sans-serif;

    --color-black: #231F20;
    --color-dark-gray: #1D1D1C;
    --color-gray: #757575;
    --color-light-gray: #adadad;
    --color-watermark: #e0e0e0;
    --color-very-light-gray: #F9F9F9;
    --color-white: #fff;
    --color-accent: #831811;
    --color-accent-rgb: rgba(131, 24, 17, 0.99);
    --color-accent-rgb-light: rgba(131, 24, 17, 0.2);
    --color-accent-light: #D75148;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  .btn{
    margin-top: 3em;
  }

  .btn-primary {
    background: var(--color-accent);
    background-image: linear-gradient(90deg, rgba(215,81,72,1) 0%, rgba(131,24,17,1) 100%);
    background-size: 100%;
    cursor: pointer;
    display: inline-block;
    padding: 0.6rem 1.5rem;
    text-decoration:none;
    color:var(--color-white);
    position: relative;
    z-index: 2;
    border: none;
    &:before {
      border-radius: inherit;
      background-image: linear-gradient(90deg, rgba(215,81,72,1) 0%, rgba(131,24,17,1) 0%);
      content: '';    
      display: block;
      height: 100%;
      position: absolute;
      top: 0; left: 0;
      opacity: 0;
      width: 100%;
      z-index: -100;
      transition: opacity 0.45s;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  .btn-secondary {
    border:1px solid var(--color-accent);
    background-size: 100%;
    cursor: pointer;
    display: inline-block;
    padding: 0.6rem 1.5rem;
    text-decoration:none;
    color:var(--color-accent);
    transition: all 0.45s;
    &:hover{
      background: var(--color-accent);
      color: var(--color-white);
    }
  }

  .btn-wide{
    padding: 0.6rem 5rem;
  }

  .btn-white{
    color:var(--color-white);
    border:1px solid var(--color-white);
    &:hover{
      border:1px solid var(--color-accent);
    }
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--color-accent) var(--color-white);
  }
  body::-webkit-scrollbar-track {
    background: var(--color-white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 6px;
    border: 3px solid var(--color-white);
  }

  hr {
    border: 0;
    height: 8px;
  }

  img {
    max-width: 100%;
  }

  ::-moz-selection {
  /* Code for Firefox */
  color: var(--color-white);
  background-color: var(--color-accent-rgb);
  }

  ::selection {
    color: var(--color-white);
    background-color: var(--color-accent-rgb);
  }

  .flex{
    display:flex;
  }

  a:not(.btn) {
    text-decoration: none;
    color: var(--color-accent);
    transition: color 300ms;
    &:hover{
      color: var(--color-accent-light);
    }
  }

`;

export default GlobalStyles;
