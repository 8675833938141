import React from "react";
import LogoFooter from "../assets/logo-footer.svg";
import { Link } from "gatsby";
import styled from "styled-components";

const FooterNavStyles = styled.nav`
  grid-column: 1 / span 4;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    margin-bottom: 2em;
  }

  img {
    margin-bottom: 3em;
  }

  .menu-left,
  .menu-right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
`;

const FooterNav = () => {
  return (
    <FooterNavStyles>
      <div className="row-footer">
        <img src={LogoFooter} alt="Logo Footer" />
      </div>
      <div className="row-footer flex">
        <div className="menu-left">
          <Link to="/" activeClassName="active">
            Home
          </Link>
          <Link to="/about-us" activeClassName="active">
            About Us
          </Link>
          <Link to="/properties" activeClassName="active">
            Properties
          </Link>
          <Link to="/lease" activeClassName="active">
            Lease
          </Link>
        </div>
        <div className="menu-right">
          <a
            href="https://jandjip.managebuilding.com/Resident/portal/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Log In
          </a>
          <Link to="/charity" activeClassName="active">
            Charity
          </Link>
          <Link to="/faqs" activeClassName="active">
            FAQ's
          </Link>
          <Link to="/contact-us" activeClassName="active">
            Contact Us
          </Link>
        </div>
      </div>
    </FooterNavStyles>
  );
};

export default FooterNav;
