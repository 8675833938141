import React from "react";
import { format } from "date-fns";
import styled from "styled-components";

const FooterNewsStyles = styled.div`
  grid-column: 9 / span 4;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
  }

  .title {
    margin-bottom: 2em;
    color: var(--color-white);
  }

  .news {
    margin-bottom: 2em;

    .news-title-date {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.6em;
      p:first-child {
        color: var(--color-white);
      }
    }

    .small {
      font-size: var(--font-small-size);
      line-height: var(--font-small-size-line-height);
      color: var(--color-light-gray);
    }

    p {
      color: var(--color-light-gray);
    }
  }
`;

const FooterNews = ({ news }) => {
  return (
    <FooterNewsStyles>
      <p className="title">Latest News</p>
      <div className="news">
        {news.map((news, i) => (
          <div className="news" key={i}>
            <div className="news-title-date">
              <p>{news.title}</p>
              <p className="small">
                {format(new Date(news.publishedAt), "MM/dd/yyyy")}
              </p>
            </div>
            <div className="news-content small">
              <p>{news.news}</p>
            </div>
          </div>
        ))}
      </div>
    </FooterNewsStyles>
  );
};

export default FooterNews;
