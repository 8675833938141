import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Grid from "./Layout/Grid";
import Header from "./Header";
import Footer from "./Footer";

import { ThemeProvider } from "styled-components";
import Theme from "../styles/theme";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />

      <ThemeProvider theme={Theme}>
        <Grid>
          <Header />
          {children}
          <Footer />
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default Layout;
