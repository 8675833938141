const links = [
  { name: "Home", url: "/", internal: true },
  { name: "About Us", url: "/about-us", internal: true },
  { name: "Properties", url: "/properties", internal: true },
  { name: "Lease", url: "/lease", internal: true },
  { name: "Charity", url: "/charity", internal: true },
  { name: "FAQ's", url: "/faqs", internal: true },
  {
    name: "Log In",
    url: "https://jandjip.managebuilding.com/Resident/portal/login",
    internal: false,
  },
  {
    name: "Contact Us",
    url: "/contact-us",
    linkClass: "btn btn-primary",
    internal: true,
  },
];

export default links;
